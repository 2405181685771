<template lang="">
  <div>
    <div class="row">
      <div class="col-lg-9 d-lg-flex d-none">
        <div class="card dveb-white-card title-card">
          <div class="row my-auto">
            <div class="col-8">
              <Steps
                :steps="3"
                :activeStep="parseInt(getProperty.instep)"
                :lastActive="true"
                :stepNames="stepNames"
                size="lg"
                v-if="getProperty"
              />
            </div>
            <div style="flex: 1">
              <div class="d-flex px-2">
                <router-link
                  :to="`/declaration/detail/${this.$route.params.id}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <b-button
                    variant="light"
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    ><i class="flaticon-home-1 text-dark p-0"></i
                  ></b-button>
                </router-link>
                <router-link
                  :to="`/transfer/sales/detail/${this.$route.params.id}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <b-button
                    variant="light"
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    ><i class="flaticon2-photo-camera text-primary p-0"></i
                  ></b-button>
                </router-link>

                <router-link
                  :to="`/declaration/detail/${this.$route.params.id}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <b-button
                    variant="light"
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    ><i class="flaticon2-sheet text-primary p-0"></i
                  ></b-button>
                </router-link>

                <router-link
                  :to="`/transfer/edit/${this.$route.params.id}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <b-button
                    variant="light"
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    ><i class="flaticon2-reload text-dark p-0"></i
                  ></b-button>
                </router-link>
              </div>
            </div>
            <div class="row flex-column justfiy-content-center" style="flex: 1">
              <b-button variant="outline-light" class="mx-auto"> 1 </b-button>
              <span
                style="font-size: 0.7rem; font-weight: 300"
                class="text-align-center mx-auto mt-0"
                >İlgili Kişiler</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <Card class="dveb-grayheader-card aside-card">
          <template #header>
            <div class="badges-area">
              <div class="badges">
                <i v-if="getProperty.status === '1' " class="badge green"></i>
              <i v-if="getProperty.watch" class="badge yellow"></i>
                          </div>
              <span>{{getProperty?.name}}</span>
            </div>
          </template>
          <div class="row">
            <div class="col p-0">
              <button   class="btn btn-outline-danger d-flex w-100 justify-content-start align-items-center mb-4" @click="updateProperty(0)">
                <i class="flaticon2-back mr-2" style="font-size:.9rem"></i>
                Geri
              </button>
            </div>
            <div class="col p-0 ml-1">
              <button  class="btn btn-outline-primary d-flex w-100 justify-content-end align-items-center mb-4" @click="updateProperty(1)">
                İleri
                <i class="flaticon2-next ml-2" style="font-size:.9rem"></i>
              </button>
            </div>
            <div class="col p-0 ml-1">
              <b-button
                variant="outline-light"
                class=" w-100"
                v-b-toggle.sidebar-right
              >
                <i class="flaticon-edit"></i>
                <span style="font-size: 0.9rem !important">Notlar</span>
              </b-button>
            </div>
          </div>
          <div class="row">
            <b-dropdown variant="light" class="w-100 mt-2 varlik-dropdown">
              <template #button-content> Varlık İşlemleri </template>
              <b-dropdown-item class="w-100"> Çoğalt</b-dropdown-item>
              <b-dropdown-item class="w-100 d-flex" @click="openDeleteHandler()"> Sil </b-dropdown-item>
            </b-dropdown>
          </div>
          
          <div class="row mt-4 d-flex d-md-none">
            <div class="col p-0 ml-1">
              <b-button
                variant="outline-light"
                style="width: 100%"
                v-b-toggle.sidebar-left
              >
                <i class="flaticon-edit"></i>
                <span style="font-size: 0.9rem !important">Varlıklar</span>
              </b-button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9">
        <div class="card dveb-white-card tab-card">
          <b-tabs v-model="tabActive" content-class="mt-3">
            <b-tab title="Varlık" ><Varlik v-if='tabActive === 0' /></b-tab>
            <b-tab title="Konum" ><Konum v-if='tabActive === 1' /></b-tab>
            <b-tab title="Taşınmaz" v-if="!tapuekle"><Tasinmaz v-if='tabActive === 2'/></b-tab>
            <b-tab title="Taşınmaz" v-if="tapuekle"><TapuEkle v-if='tabActive === 2'/></b-tab>
            <b-tab title="Taşınır"><Tasinir v-if='tabActive === 3'/></b-tab>
            <b-tab title="Dosyalar"><Dosyalar v-if='tabActive === 4'/></b-tab>
            <b-tab title="Albüm" v-if="!albumEdit"><Album v-if='tabActive === 5'/></b-tab>
            <b-tab title="Albüm" v-if="albumEdit" ><AlbumEdit v-if='tabActive === 5' /></b-tab>
            <b-tab title="Ziyaretler"><Ziyaretler v-if='tabActive === 6'/></b-tab>
            <b-tab title="Ekspertiz"><Ekspertiz v-if='tabActive === 7'/></b-tab>
            <b-tab title="Kira"><Kira v-if='tabActive === 8'/></b-tab>
            <b-tab title="Masraf"><Masraf v-if='tabActive === 9'/></b-tab>
            <b-tab title="Hatırlatmalar"><Hatirlatmalar v-if='tabActive === 10'/></b-tab>
            <b-tab title="İlgilenen Kişiler"><Kisiler v-if='tabActive === 11'/></b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-lg-3 d-lg-flex d-none">
        <div class="card dveb-gray-card aside-gray-card">
          <div class="card-header">{{getProperty?.owner_name}}</div>
          <div class="mt-4 d-md-flex d-none">
            <MapView class="map" />
          </div>
          <div class="d-inline-flex mt-4">
            <div class="mx-auto d-inline-flex flex-column">
              <b-button
                v-b-tooltip.hover
                title="Varlık Sahibinin Aynı İldeki Varlıkları"
                variant="light"
              >
                <i class="flaticon-user"></i>
                <span class="badge badge-secondary text-primary ml-4">1</span>
              </b-button>
              <p class="text-bold mt-4">Hatay, ANTAKYA</p>
            </div>
          </div>
          <div class="bg-dveb-light mt-4">
            <p class="text-bold">{{getProperty?.real_count}} Tapu</p>
          </div>
          <div class="bg-dveb-light mt-4">
            <button class="btn btn-secondary btn-sm mr-4">
              Tapuları Listele
            </button>
            <button class="btn btn-xs" @click="openDeleteHandler()">
              <i class="flaticon2-trash text-danger"></i>
              Sil
            </button>
          </div>
            <div class="mt-4 d-flex">
              <div class="circle">R</div>
              <div>
                <h4>NAİL BURAK DUYGULUCAN</h4>
                <h6>Saha Operasyon Uzmanı</h6>
                <div class="d-flex mt-3">
                  <p class="mr-4"><span class="font-weight-bold">İş: </span> 0</p>
                  <p class="mr-4"><span class="font-weight-bold">Dahili: </span> 0</p>
                </div>
                <div class="d-flex">
                  <p class="font-weight-bold mr-3">Mail:</p>
                  <p>rasim@rasim.com</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="notes-sidebar">
        <b-sidebar id="sidebar-right" right>
          <div
            class="card dveb-gray-card aside-gray-card"
            style="overflow-y: auto"
          >
            <div class="card-header">
                {{notes?.length}} NOT
              <b-button variant="light ml-4" @click="noteModalShow = true;"
                >NOT EKLE
              </b-button>
            </div>
            <div
              class="card mt-4 dveb-card-white p-4"
              v-for="(note, key) in notes"
              :key="key"
              style="border-radius: 0 !important"
            >
              <div>
                <h4>{{ note.user_full_name }}</h4>
                <span class="text-bold">{{ dateFormatter( note.date_added) }}</span>
                <p class="mt-4">{{ note.note }}</p>
                <hr />
                <div class="row">
                  <b-button
                    variant="danger"
                    class="ml-auto mx-4"
                    @click="deleteNote(note)"
                  >
                    Sil
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-sidebar>
        <b-sidebar id="sidebar-left" left>
          <div class="card dveb-gray-card aside-gray-card">
            <div class="card-header">{{getProperty?.owner_name}}</div>
            <div class="mt-4">
              <MapView class="map"  />
            </div>
            <div class="d-inline-flex mt-4">
              <div class="mx-auto d-inline-flex flex-column">
                <b-button
                  v-b-tooltip.hover
                  title="Varlık Sahibinin Aynı İldeki Varlıkları"
                  variant="light"
                >
                  <i class="flaticon-user"></i>
                  <span class="badge badge-secondary text-primary ml-4">{{getProperty?.real_count}}</span>
                </b-button>
                <p class="text-bold mt-4">Hatay, ANTAKYA</p>
              </div>
            </div>
            <div class="bg-dveb-light mt-4">
              <p class="text-bold">1 Tapu</p>
            </div>
            <div class="bg-dveb-light mt-4">
              <button class="btn btn-secondary btn-sm mr-4">
                Tapuları Listele
              </button>
              <button class="btn btn-xs" @click="openDeleteHandler">
                <i class="flaticon2-trash text-danger"></i>
                Sil
              </button>
            </div>
          </div>
        </b-sidebar>
      </div>
    </div>
    <b-modal v-model="noteModalShow" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Not Ekle</h4>
      </template>
      <div class="form-group">
        <label for="note">Not</label>
        <textarea
          name=""
          v-model="newNote"
          id="note"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="noteModalShow = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      modalid="deleteProperty"
      redirectUrl="/properties/find"
    />
    <DeleteOperation
      :url="'property/note'"
      :params="deleteNoteId"
      @updateList="getNotesList"
      modalid="deleteNote"
    />
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { PROPERTY_NOTES,POST_NOTES } from "@/core/services/store/property/notes.module";
import Swal from "sweetalert2"
import moment from "moment";
moment.locale("tr")

export default {
  name: "propertyDetail",
  components: {
    Steps: () => import("@/components/global/StepBar"),
    MapView: () => import("@/components/dashboard/MapView"),

    Varlik: () => import("@/components/property/DetailTabs/Varlik"),
    Konum: () => import("@/components/property/DetailTabs/Konum"),
    Tasinmaz: () => import("@/components/property/DetailTabs/Tasinmaz"),
    Tasinir: () => import("@/components/property/DetailTabs/Tasinir"),
    Dosyalar: () => import("@/components/property/DetailTabs/Dosyalar"),
    Album: () => import("@/components/property/DetailTabs/Album"),
    AlbumEdit: () => import("@/components/property/DetailTabs/AlbumEdit"),
    TapuEkle: () => import("@/components/property/DetailTabs/TapuEkle"),
    Ziyaretler: () => import("@/components/property/DetailTabs/Ziyaretler"),
    Ekspertiz: () => import("@/components/property/DetailTabs/Ekspertiz"),
    Kira: () => import("@/components/property/DetailTabs/Kira"),
    Masraf: () => import("@/components/property/DetailTabs/Masraf"),
    Hatirlatmalar: () => import("@/components/property/DetailTabs/Hatirlatmalar"),
    Kisiler: () => import("@/components/property/DetailTabs/Kisiler"),
  },
  data() {
    return {
      noteModalShow: false,
      notes: [],
      stepNames: ["Hazırlık", "Satış", "Devir"],
      newNote: "",
      badges: [1, 2, 3],
      badgesEnum: ["green", "yellow", "red"],
      albumEdit: false,
      tabActive: 0,
      tapuekle  : false,
      deleteOperationUrl: "",
      deleteIds: "",
      deleteNoteId : ""

      
    };
  },
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Varlık Detay" },
      ]);
    },
  methods: {
    addNewNote() {

      let payload = {
        user_token: this.myUser,
        note: this.newNote,
        user_id : this.currentUser.user_id,
        property_id:this.$route.params.id
      };
      this.$store.dispatch(POST_NOTES,payload).then((response)=>{
        if(response.code === 200){
          this.$generateNotification(this, 'success', "Not başarıyla eklendi.");
          this.getNotesList()
          this.newNote = ""
          this.noteModalShow = false
        }
      }).catch((err)=>{
        this.$generateNotification(this, 'error', "Not eklenirken bir hata ile karşılaşıldı");

      })

    },
    getNotesList(){
      const payload = {property_id : this.$route.params.id,myUser:this.myUser}
      this.$store.dispatch(PROPERTY_NOTES,payload).then((response)=>{
        this.notes = response
      })

    },
    deleteNote(key) {
      this.deleteNoteId = `?user_token=${this.myUser}&note_id=${key._id.$oid}`
      this.$bvModal.show("modal-deleteOperationdeleteNote");
     
    },
    dateFormatter(date){
      return moment(date,"DD/MM/YYYY  h:mm:ss").format("LLL")
    },
    updateProperty(status){
      let swalText = status ? "Bu varlığın durumunu ilerletmek istiyor musunuz?" : "Bu varlığın durumunu geriletmek istiyor musunuz?"
      let stat = this.getProperty.instep 
      let newStatus = status ? parseInt(stat) + 1 : parseInt(stat) -1 
      Swal.fire({
          title: 'Emin misin?',
          text: swalText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText:"Hayır",
          confirmButtonText: 'Evet'
        }).then((result) => {
          const formData = new FormData()
          formData.append("property_id",this.$route.params.id)
          formData.append("user_token",this.myUser)
          formData.append("instep",newStatus)
          if (result.isConfirmed) {
            this.$ApiService.put("property/property",formData).then((response)=>{
              if(response.data.status.code === 200){
                
                window.location.reload()
              }
            })
          }
        })

    },
    openDeleteHandler() {
      this.deleteOperationUrl = `property/property`;
      this.deleteIds = `?user_token=${this.myUser}&property_id=${this.$route.params.id}`;
      this.$bvModal.show('modal-deleteOperationdeleteProperty');
    },

  },

  created() {
    this.getNotesList()
    this.$watch((this.tapuekle = this.$route.name === "tapuekle" ? true : false));


  },
  watch: {
    $route(to, from) {
      this.albumEdit = this.$route.params.albumid ? true : false;
      this.tapuekle = this.$route.name === "tapuekle" ? true : false;
    },
    tabActive: function () {
      if (this.$route.params.albumid || this.$route.name === "tapuekle") {
        const currentParams = this.$router.currentRoute.params;
        this.$router.push(`/properties/find/detail/${currentParams.id}/`);
      }
    },
    
    
  },
  mounted(){
    window.addEventListener('resize', () => {
      this.onResize
  })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  computed:{
    ...mapGetters(["myUser","currentUser","getProperty"])
  },
  

};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
}
.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;

      border-radius: 50%;
      padding: 0 !important;
      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
  span {
    font-size: 1rem;
  }
}
.title-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.map {
  height: 200px;
}
</style>
